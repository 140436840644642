//import logo from './logo.svg';
import ps_logo from './logo_PS.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ps_logo} className="PS-logo" alt="logo" /> 



        <h3 className="head3-justified-text">PERSCEPTRE TECHNOLOGIES</h3>

        <p className="ps-justified-text">

        
        Our beloved users, customers and esteemed partners, <br /> <br />
        We started PerSceptre from the humble beginnings of our university dorms in sweatpants <br />
        and with big dreams. Throughout this journey lasting 7 years we have learnt a lot, made <br />
        numerous memories and forged time tested relationships<br /><br />
        Our Products, Services and Philosophy has touched lives and Inspired many<br />
        Using cutting edge technologies such as: Virtual Reality, Augmented Reality,<br />
        Haptics & Navigation systems using our Embedded Electronics, Optical Sytems,<br />
        Cloud Computing, Software Suite and Hardware Headsets<br /><br />
        Across numerous sectors we served such as: Immersive Gaming & Entertainment,<br />
        Defence, Education, Corporate Training, Simulations and 3D Visualization<br /><br />
        We have been fortunate enough to have been recognized for our innovation with many<br />
        accolades along the way including but not limited to: Y-Combinator Startup School 2018,<br />
        GSE Accelerator Demo Day 2019 and The Prestigious Thiel Fellowship 2020<br /><br />
        During Q1 2023 the board of directors decided to proceed with the company’s acquisition<br />
        by a new owner. As of publishing this public letter, our corporate acquisition is now<br />
        complete. The assets and intellectual properties have been successfully transferred to the new owner<br />
        We would like to thank all of you from the bottom of our hearts for believing in our vision<br />
        and entrusting us with your most valuable suggestions, criticism, contracts and smiles.<br /><br />

        </p>

        <h3 className="bottom-justified-text">
        Love,<br />
        Raghav Udaiwal<br />
        Jaivardhan Singh Rathore<br />
        and the entire PerSceptre Team<br />
        </h3>

      </header>
    </div>
  );
}

export default App;
